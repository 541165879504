import React from 'react';

import Home from './Home';
import Miners from './Miners';
import Error from './Error';
import Res from './Res';
import Mod from './Mod';
import Craft from './Craft';
import Shop from './Shop';
import Alchemy from './Alchemy';
import Quiz from './Quiz';
import Clans from './Clans';
import TolminPower from './TolminPower';
import MortalKombat from './MortalKombat';
import Fotaf from './Fotaf';
import Price from './Price';
import ForceGraph from './ForceGraph';

import { Route, Switch } from 'react-router-dom';

const Navigation = () => {
    return (
       <Switch>
			 <Route path="/" component={Home} exact/>
			 <Route path="/miners" component={Miners} exact/>
			 <Route path="/res" component={Res} exact/>
			 <Route path="/mod" component={Mod} exact/>
			 <Route path="/craft" component={Craft} exact/>
			 <Route path="/shop" component={Shop} exact/>
			 <Route path="/alchemy" component={Alchemy} exact/>
			 <Route path="/fotaf" component={Fotaf} exact/>
			 <Route path="/clans" component={Clans} exact/>
			 <Route path="/tp" component={TolminPower} exact/>
			 <Route path="/quiz" component={Quiz} exact/>
			 <Route path="/mortalkombat" component={MortalKombat} exact/>
			 <Route path="/price" component={Price} exact/>
			 <Route path="/ForceGraph" component={ForceGraph} exact/>
			 <Route component={Error}/>
		</Switch>
    );
}
 
export default Navigation;