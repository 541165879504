import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import SwipeableDrawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StorefrontIcon from '@material-ui/icons/Storefront';

import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import GavelIcon from '@material-ui/icons/Gavel';
import ExposurePlus2Icon from '@material-ui/icons/ExposurePlus2';
import WavesIcon from '@material-ui/icons/Waves';

import PollIcon from '@material-ui/icons/Poll';
import Navigation from './Navigation';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import { NavLink, useLocation } from 'react-router-dom';

		 
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('xl')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('xl')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
//    [theme.breakpoints.up('xl')]: {
//      display: 'none',
//    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
	overflowX: 'hidden'
//    padding: theme.spacing(3),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation());
}


function Menu(props) {
	
	let query = useQuery();

	
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
//	console.log(props)
  const [page, setPage] = React.useState('Аватариум');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        
          <ListItem button component={NavLink} to="/" activeClassName="Mui-selected" exact onClick={()=> setPage('Главная')}>
            <ListItemIcon>
            	<HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Домашняя'} />
          </ListItem>
          <ListItem button component={NavLink} to="/miners" activeClassName="Mui-selected" exact onClick={()=> setPage('Шахты')}>
            <ListItemIcon>
            	<FilterHdrIcon />
            </ListItemIcon>
            <ListItemText primary={'Шахты'} />
          </ListItem>
          <ListItem button component={NavLink} to="/res" activeClassName="Mui-selected" exact onClick={()=> setPage('Ресурсы')}>
            <ListItemIcon>
            	<PollIcon />
            </ListItemIcon>
            <ListItemText primary={'Ресурсы'} />
          </ListItem>
          <ListItem button component={NavLink} to="/mod" activeClassName="Mui-selected" exact onClick={()=> setPage('Модификационная')}>
            <ListItemIcon>
            	<ExposurePlus2Icon />
            </ListItemIcon>
            <ListItemText primary={'Модификационная'} />
          </ListItem>
          <ListItem button component={NavLink} to="/craft" activeClassName="Mui-selected" exact onClick={()=> setPage('Крафт вещей')}>
            <ListItemIcon>
            	<GavelIcon />
            </ListItemIcon>
            <ListItemText primary={'Крафт вещей'} />
          </ListItem>
          <ListItem button component={NavLink} to="/shop" activeClassName="Mui-selected" exact onClick={()=> setPage('Магазин')}>
            <ListItemIcon>
            	<StorefrontIcon />
            </ListItemIcon>
            <ListItemText primary={'Магазин'} />
          </ListItem>
			<ListItem button component={NavLink} to="/alchemy" activeClassName="Mui-selected" exact onClick={()=> setPage('Алхимия')}>
            <ListItemIcon>
            	<WavesIcon />
            </ListItemIcon>
            <ListItemText primary={'Алхимия'} />
          </ListItem>
		 <ListItem button component={NavLink} to="/fotaf" activeClassName="Mui-selected" exact onClick={()=> setPage('Кузница Древнего Пламени')}>
            <ListItemIcon>
            	<WavesIcon />
            </ListItemIcon>
            <ListItemText primary={'Кузница Древнего Пламени'} />
          </ListItem>
			<ListItem button component={NavLink} to="/clans" activeClassName="Mui-selected" exact onClick={()=> setPage('Кланы')}>
            <ListItemIcon>
            	<WavesIcon />
            </ListItemIcon>
            <ListItemText primary={'Кланы'} />
          </ListItem>
			<ListItem button component={NavLink} to="/tp" activeClassName="Mui-selected" exact onClick={()=> setPage('Толминосила')}>
            <ListItemIcon>
            	<WavesIcon />
            </ListItemIcon>
            <ListItemText primary={'Толминосила'} />
          </ListItem>
			<ListItem button component={NavLink} to="/quiz" activeClassName="Mui-selected" exact onClick={()=> setPage('Опросник')}>
            <ListItemIcon>
            	<WavesIcon />
            </ListItemIcon>
            <ListItemText primary={'Опросник'} />
          </ListItem>
			<ListItem button component={NavLink} to="/mortalkombat" activeClassName="Mui-selected" exact onClick={()=> setPage('Симулятор боев')}>
            <ListItemIcon>
            	<WavesIcon />
            </ListItemIcon>
            <ListItemText primary={'Симулятор боев'} />
          </ListItem>
      </List>
    </div>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar  style={{zIndex: 100, display: ((query.toString().indexOf('Force') != -1) || (query.toString().indexOf('mortal') != -1)) ? 'none' : 'block'}}>
		  <IconButton
				color="inherit"
				aria-label="open drawer"
				edge="start"
				onClick={handleDrawerToggle}
				className={classes.menuButton}
			  >
				<MenuIcon />
			  </IconButton>
	  </Toolbar>
      <AppBar position="fixed" className={classes.appBar}  style={{display: ((query.toString().indexOf('Force') != -1) || (query.toString().indexOf('mortal') != -1)) ? 'none' : 'inherit'}}>
        <Toolbar>
          <IconButton
				color="inherit"
				aria-label="open drawer"
				edge="start"
				onClick={handleDrawerToggle}
				className={classes.menuButton}
			  >
				<MenuIcon />
			  </IconButton>
          <Typography variant="h6" noWrap>
            {page}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders" >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <SwipeableDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </SwipeableDrawer>
        <Hidden xlDown implementation="css">
          <SwipeableDrawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} style={{zIndex: 100, display: ((query.toString().indexOf('Force') != -1) || (query.toString().indexOf('mortal') != -1)) ? 'none' : 'block'}}/>
			<Navigation/>
      </main>
    </div>
  );
}
//
//Menu.propTypes = {
//  /**
//   * Injected by the documentation to work in an iframe.
//   * You won't need it on your project.
//   */
//  container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
//};

export default Menu;