import React from 'react';
import ReactECharts from 'echarts-for-react';
import graphData from './graph_data_all.json';  // Путь к вашему JSON файлу

const ForceGraphWithBarChart = () => {
  // Функция для генерации контрастных цветов
  const getContrastingColor = () => {
    const letters = '123456789A';  // Ограничиваем выбор символов для создания светлого цвета
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 9)];
    }
    return color;
  };

  // Функция для получения уникальных цветов для кластеров
  const getClusterColors = (nodes) => {
    const clusterColors = {};
    nodes.forEach(node => {
      const cluster = node.cluster || 'default';
      if (!clusterColors[cluster]) {
        clusterColors[cluster] = getContrastingColor();
      }
    });
    return clusterColors;
  };

  // Генерация цветов для каждого кластера
  const clusterColors = getClusterColors(graphData.nodes);

  // Модификация данных узлов с назначением цвета для каждого кластера
  const modifiedNodes = graphData.nodes.map(node => ({
    ...node,
    label: {
      show: true,
      position: 'right',
      formatter: '{b}',  // Отображение имени
      color: clusterColors[node.cluster || 'default']  // Применение цвета для кластера
    }
  }));

  // Подсчет количества узлов в каждом кластере
  const clusterCounts = graphData.nodes.reduce((acc, node) => {
    const cluster = node.cluster;
    acc[cluster] = (acc[cluster] || 0) + 1;
    return acc;
  }, {});

  // Преобразуем данные в формат для столбчатой диаграммы
  const barChartData = Object.entries(clusterCounts)
    .sort((a, b) => a[1] - b[1]) // Сортируем по убыванию
    .map(([cluster, count]) => ({
      name: cluster,
      value: count,
      nodeNames: graphData.nodes
        .filter(node => (node.cluster) === parseInt(cluster))
        .map(node => node.name)
    }));

  // Опции для форс-графа
  const forceGraphOptions = {
    backgroundColor: '#fff',
    title: {
      text: 'Force-Directed Graph',
      subtext: 'Данные из DataFrame',
      top: 'top',
      left: 'center'
    },
    tooltip: {
      show: true,
      trigger: 'item',
      formatter: '{b}' // Показать имя аватара
    },
    series: [
      {
        type: 'graph',
        layout: 'force',
        data: modifiedNodes,
        links: graphData.links,
        force: {
          repulsion: 8000,
          gravity: 0.2,
          edgeLength: 30,
          friction: 0.6
        },
        label: {
          show: true,
          position: 'right',
          formatter: '{b}'  // Отображение никнеймов
        },
        roam: true,
        draggable: false,
        lineStyle: {
          width: 1,
          opacity: 0.9
        }
      }
    ]
  };

  // Опции для столбчатой диаграммы
  const barChartOptions = {
    title: {
      text: 'Кластеры',
      left: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return `<div style="max-width: 300px; white-space: normal; word-wrap: break-word;">
          <b>Кластер: ${params.name}</b><br/>
          Количество: ${params.value}<br/>
          Имена: ${params.data.nodeNames.join(', ')}
        </div>`;
      }
    },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: barChartData.map(item => item.name),
      axisLabel: {
        fontSize: 14 // Увеличиваем размер шрифта для подписей
      }
    },
    series: [
      {
        type: 'bar',
        data: barChartData.map(item => ({
          value: item.value,
          name: item.name,
          nodeNames: item.nodeNames
        })),
        label: {
          show: true,
          position: 'right',
          formatter: '{c}' // Показываем количество справа от столбика
        },
        barWidth: '10px' // Увеличиваем ширину столбиков
      }
    ]
  };

  return (
    <div style={{ width: '100%', height: '97vh', display: 'flex' }}>
      {/* Столбчатая диаграмма */}
      <div style={{ 
        width: '40%', 
        height: '100%', 
        overflowY: 'auto', // Добавляем скролл
        scrollbarWidth: 'none', // Скрываем стандартный скролл
        msOverflowStyle: 'none', // Для IE/Edge
        '&::-webkit-scrollbar': { // Для WebKit
          display: 'none'
        }
      }}>
        <ReactECharts 
          option={barChartOptions} 
          style={{ 
            height: `${graphData.nodes.length*3}px`, // Увеличиваем высоту диаграммы
            width: '100%' 
          }} 
        />
      </div>

      {/* Форс-граф */}
      <div style={{ width: '60%', height: '100%' }}>
        <ReactECharts option={forceGraphOptions} style={{ height: '100%', width: '100%' }} />
      </div>
    </div>
  );
};

export default ForceGraphWithBarChart;